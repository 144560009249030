import React from 'react';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../config/Configuration';

const ContactPage: React.FC = () => {

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Contact</h1>
      <p className="lead">If you'd like to check the status of your tree pickup, check the link your email when you scheduled the pickup.</p>
      <p className="lead">If you have any other questions, please reach out to us at <a href={"mailto:" + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>.</p>
      {CONTACT_PHONE && <p className="lead">You can also reach us by phone at <a href={"tel:" + CONTACT_PHONE}>{CONTACT_PHONE}</a>.</p>}
    </div>
  );
};

export default ContactPage;