import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../config/Configuration";

// Generic function to create async thunk
const createFetchThunk = (name: string) => {
  return createAsyncThunk(`${name}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_URL}/${name}`, {
        headers: {
          Authorization: jwt,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  });
};

// Generic function to create slice
const createGenericSlice = (name: string, fetchThunk: any) => {
  return createSlice({
    name,
    initialState: {
      data: [],
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.errorMessage = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchThunk.pending, (state) => {
          state.isFetching = true;
          state.isError = false;
          state.isSuccess = false;
          state.errorMessage = "";
        })
        .addCase(fetchThunk.fulfilled, (state, action) => {
          state.isFetching = false;
          state.isSuccess = true;
          state.data = action.payload;
        })
        .addCase(fetchThunk.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = action.payload as string;
        });
    },
  });
};

// Create thunks for responses and houses
export const fetchSiteConfig = createFetchThunk("site-config");

// Create slices for responses and houses
export const siteConfigSlice = createGenericSlice("site_config", fetchSiteConfig);

export const siteConfigReducer = siteConfigSlice.reducer;
