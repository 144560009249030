import React from 'react';

const NotFoundPage: React.FC = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="display-4">Not Found</h1>
          <p>The page you're looking for doesn't exist.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;