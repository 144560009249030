export const TROOP_NAME = "Troop 600";
export const TROOP_LOCATION = "Bellevue, WA";
export const TROOP_WEBSITE = "https://www.troop600.com";

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia2FlZGVuYiIsImEiOiJja2FyMmRyeHcwZ3dmMnFwbGhuZTc2MXgyIn0.vmW7QzXVTA1D879Fs3e0xA";
export const API_URL = "https://tree-tags-api.troop600.com/public";

export const CONTACT_EMAIL = "tree-tags@troop600.com";
export const CONTACT_PHONE = "";
