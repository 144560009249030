import React from 'react';
import { useSelector } from 'react-redux';
import { TROOP_LOCATION, TROOP_NAME, TROOP_WEBSITE } from '../config/Configuration';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const { data, isFetching, isError } = useSelector((state: any) => state.siteConfig);
  const pickupConfig = data.find((entry: any) => entry.name === 'pickup');
  const pickupDay = pickupConfig?.data?.date ? new Date(pickupConfig?.data?.date) : null;
  const dropoffAllowed = pickupConfig?.data?.dropoffAllowed || false;
  const dropoffLocation = pickupConfig?.data?.dropoffLocation || '';
  const dropoffHours = pickupConfig?.data?.dropoffHours || '';
  return (
    <footer className="container py-5">
      <div className="row">
        <div className="col-12 col-md-4 mb-3">
          <h5>About Us</h5>
          <p>
            {TROOP_NAME} is based out of {TROOP_LOCATION} and includes boys from 12-18 years of age.
            If you'd like to learn more, please visit our <a href={TROOP_WEBSITE}>troop website</a>.          </p>
        </div>

        <div className="col-12 col-md-4 mb-3">
          <h5>Tree Pickup</h5>
          <p>
            Please remove all decorations and leave your tree on the curb outside your house prior to pickup.
            {pickupDay && (
              <>
                {" "}The scouts will pick-up trees from your location on {pickupDay.toLocaleDateString('en-US', { timeZone: 'UTC' })}.
              </>
            )}
            {" "}Pick-up is only available for residents of select zip codes near {TROOP_LOCATION}.
          </p>
        </div>

        <div className="col-12 col-md-4 mb-3">
          <h5>Missed Pickup</h5>
          <p>
            We do our best to collect all trees, but occasionally we miss a couple. If you are missed please call the number on our <Link to="/contact">contact page</Link> to let us know.
            {pickupDay && dropoffAllowed && (
              <>
                {" "}You can also drop off your tree on {pickupDay.toLocaleDateString('en-US', { timeZone: 'UTC' })} at {dropoffLocation} between {dropoffHours}.
              </>
            )}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;