import React, { useEffect } from 'react';
import { Geocoder } from '@mapbox/search-js-react';
import { callAPI } from '../config/API';
import { prettyPrintAddress } from './ConfirmationPage';
import LoadingIndicator from './LoadingIndicator';
import { useSelector } from 'react-redux';
import { MAPBOX_TOKEN } from '../config/Configuration';
import { Link } from 'react-router-dom';
import { LngLat } from 'react-map-gl';

interface ProductPageProps {
  name: string;
  imageUrl: string;
  configName: string;
  pickup?: boolean;
}

const Geocoder2 = Geocoder as any;

const ProductPage: React.FC<ProductPageProps> = ({ name, imageUrl, configName, pickup }) => {
  const { data, isFetching, isError } = useSelector((state: any) => state.siteConfig);

  const [address, setAddress] = React.useState('');
  const [lat, setLat] = React.useState(0);
  const [lng, setLng] = React.useState(0);
  const [scoutId, setScoutId] = React.useState('');
  const [hideScoutId, setHideScoutId] = React.useState(false);
  const [moreThan1Tree, setMoreThan1Tree] = React.useState(false);

  const [waitingForAction, setWaitingForAction] = React.useState(false);
  const [actionError, setActionError] = React.useState<string>("");

  const productConfig = data.find((entry: any) => entry.name === configName);
  const description = productConfig?.data?.description;
  const stripeUrl = productConfig?.data?.stripeUrl;
  const stripeUrlMultiple = productConfig?.data?.stripeUrlMultiple;
  const suggestedDonation = productConfig?.data?.suggestedDonation;

  const pickupConfig = data.find((entry: any) => entry.name === 'pickup');
  const pickupDate = new Date(pickupConfig?.data?.date);
  const dropoffAllowed = pickupConfig?.data?.dropoffAllowed;
  const dropoffLocation = pickupConfig?.data?.dropoffLocation;
  const dropoffHours = pickupConfig?.data?.dropoffHours;
  const pickupZipCodes = pickupConfig?.data?.zipCodes;
  const mapCenter: LngLat = pickupConfig?.data?.mapCenter;
  const mapBounds: [number, number, number, number] = pickupConfig?.data?.mapBounds;

  const zipCodeInvalid = pickup && pickupZipCodes && address && !addressInZipCodes(address, pickupZipCodes);
  const donateBtnDisabled: boolean = (!!pickup && !!zipCodeInvalid) || (!!pickup && !address);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const clearAddress = () => {
    setAddress('');
    setLat(0);
    setLng(0);
  };

  const setAddrDetails = (res: any) => {
    setAddress(res.properties.full_address);
    setLat(res.geometry.coordinates[1]);
    setLng(res.geometry.coordinates[0]);
    console.log(res);
  }

  const handleDonateClick = () => {
    const donation = {
      type: pickup ? 'tree-tag' : 'donation',
      address,
      scoutId,
      lat,
      lng,
    };
    setWaitingForAction(true);
    callAPI(donation)
      .then((result) => {
        setWaitingForAction(false);
        console.log(result);
        const id = result.orderId;
        if (id) {
          redirectToStripe(id);
        } else {
          setActionError("An error occurred while processing your request. Please try again later.");
        }
      })
      .catch((error) => {
        setWaitingForAction(false);
        setActionError(error.message);
      });
  };

  const redirectToStripe = (orderId: string) => {
    const url = new URL((moreThan1Tree && stripeUrlMultiple) ? stripeUrlMultiple : stripeUrl);
    url.searchParams.append('client_reference_id', orderId);
    window.location.href = url.toString();
  }

  useEffect(() => {
    // Get scoutId from URL query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const scoutIdParam = urlParams.get('scoutId');
    if (scoutIdParam) {
      setScoutId(scoutIdParam);
      setHideScoutId(true);
    }
  }, []);

  if (waitingForAction) {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="display-4">Processing your request...</h1>
            <LoadingIndicator />
            <p>You'll be redirected shortly.</p>
          </div>
        </div>
      </div>
    );
  }

  if (actionError) {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="display-4">Oops! We've run into an issue.</h1>
            <p className="alert alert-danger mt-4">{actionError}</p>
            <p>If this issue persists, reach out to us <Link to="/contact">here</Link>.</p>
            <button onClick={handleDonateClick} className="btn btn-primary mt-3">Try Again</button>
          </div>
        </div>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-6 text-center">
            <img src={imageUrl} alt={name} className="img-fluid mt-4 w-50 w-md-100" />
          </div>
          <div className="col-md-6">
            <h1 className="display-4 text-center">{name}</h1>
            <LoadingIndicator />
            <p className="lead mt-4 text-center">Hang tight! We're loading the page for you.</p>
          </div>
        </div>
      </div>
    );
  }

  if (!productConfig || isError || (pickup && !mapBounds) || (pickup && !mapCenter)) {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-6 text-center">
            <img src={imageUrl} alt={name} className="img-fluid mt-4 w-50 w-md-100" />
          </div>
          <div className="col-md-6">
            <h1 className="display-4 text-center">{name}</h1>
            <p className="lead mt-4 text-center">We're sorry, we could not load the required information from the server.</p>
            <p className="text-center">If this issue persists, please reach out to us <Link to="/contact">here</Link>.</p>
          </div>
        </div>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-6 text-center">
            <img src={imageUrl} alt={name} className="img-fluid mt-4 w-50 w-md-100" />
          </div>
          <div className="col-md-6">
            <h1 className="display-4 text-center">{name}</h1>
            <p className="lead mt-4 text-center">Hang tight! We're loading the page for you.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-6 text-center">
          <img src={imageUrl} alt={name} className="img-fluid mt-4 w-50 w-md-100" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 text-center">{name}</h1>
          <p className="lead mt-4 text-center">{description}</p>
          <form className="text-start">
            <fieldset>
              <legend>Scout Information (Optional)</legend>
              {hideScoutId ? (
                <div className="mb-3">
                  <div className="alert alert-success mt-2">
                    <b>Scout ID:</b> {scoutId} <button onClick={() => setHideScoutId(false)} className="btn btn-link">Change</button>
                  </div>
                </div>
              ) : (
                <div className="mb-3">
                  <label htmlFor="scoutId" className="form-label">Enter the Scout ID of the scout you worked with, if you have one:</label>
                  <input
                    type="text"
                    id="scoutId"
                    placeholder="Scout ID"
                    className="form-control"
                    value={scoutId}
                    onChange={(e) => setScoutId(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                </div>
              )}
            </fieldset>
            {pickup && (
              <>
                <fieldset>
                  <legend>Pickup Information</legend>
                  <div className="mb-3">
                    <label htmlFor="numTrees" className="form-label">Do you have more than 1 tree?</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="numTrees"
                        checked={moreThan1Tree}
                        onChange={(e) => setMoreThan1Tree(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="numTrees">{moreThan1Tree ? 'Yes' : 'No'}</label>
                    </div>
                    {moreThan1Tree && (
                      <div className="alert alert-warning mt-2">
                        <b>You will need to select the number of trees on the next screen.</b> The suggested donation is {currencyFormatter.format(suggestedDonation)} per tree. Thank you for your support!
                      </div>
                    )}
                  </div>
                  {!address ? (
                    <div className="mb-3">
                      <Geocoder2
                        options={{
                          proximity: mapCenter,
                          bbox: mapBounds,
                        }}
                        placeholder="Enter your pickup address"
                        onChange={(value: any) => { if (value !== address) { clearAddress() } }}
                        onClear={() => clearAddress()}
                        onRetrieve={(res: any) => setAddrDetails(res)}
                        accessToken={MAPBOX_TOKEN}
                      />
                      <label htmlFor="geocoder" className="form-label">Note: We only pick up in select zip codes. Enter your address above to check.</label>
                    </div>
                  ) : zipCodeInvalid ? (
                    <div className="mb-3">
                      <div className='alert alert-danger'>
                        <label htmlFor="address" className="form-label fw-bold">Your Pickup Address:</label>
                        <p className="form-control-plaintext">{prettyPrintAddress(address)}</p>
                        <p>
                          {dropoffAllowed ? (
                            `Sorry, you are outside of our pickup zone. You can still drop off your tree at ${dropoffLocation} on ${pickupDate.toLocaleDateString('en-US', { timeZone: 'UTC' })} between ${dropoffHours}.`
                          ) : (
                            "Sorry, you are outside of our pickup zone. We only pick up in select zip codes. Please enter a valid address."
                          )}
                        </p>
                        <button onClick={() => setAddress("")} className="btn btn-link">Change Address</button>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <div className='alert alert-success'>
                        <label htmlFor="address" className="form-label fw-bold">Your Pickup Address:</label>
                        <p className="form-control-plaintext">{prettyPrintAddress(address)}</p>
                        <button onClick={() => setAddress("")} className="btn btn-link">Change Address</button>
                      </div>
                    </div>
                  )}
                </fieldset>
              </>
            )}
            <p>
              <small>
                Donations support our scouting program, including membership dues, summer camps, troop supplies, and merit badges.
              </small>
            </p>
            <p>
              <small>
                When you click the button below, you will be redirected to our payments provider, Stripe. Our suggested donation is {currencyFormatter.format(suggestedDonation)}.
              </small>
            </p>
            <button onClick={handleDonateClick} className="btn btn-primary mt-3" disabled={donateBtnDisabled}>Donate</button>
          </form>
        </div>
      </div>
    </div >
  );
};

function addressInZipCodes(address: string, zipCodes: string[]): boolean {
  const zips = zipCodes.map((zip) => Number(zip));
  const zipCodeMatches = address.match(/\b\d{5}(?:-\d{4})?\b/g);
  if (!zipCodeMatches) return false;
  const zipCode = parseInt(zipCodeMatches[zipCodeMatches.length - 1]);

  return zips.includes(zipCode);
}
export default ProductPage;
