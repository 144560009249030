import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getOrder } from '../config/API';
import { TROOP_LOCATION, TROOP_NAME } from '../config/Configuration';
import HouseMap from './HouseMap';
import LoadingIndicator from './LoadingIndicator';
import { useSelector } from 'react-redux';

function DonationDetails({ donation }: { donation: any }) {
  if (!donation) {
    return null;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="card mt-4">
      <div className="card-body">
        <h5 className="card-title">Donation Details</h5>
        <p className="card-text">Order ID: <code>{donation.id}</code></p>
        <p className="card-text">Your receipt was emailed to: {donation.customer_email}</p>
        <p className="card-text">Amount: {formatter.format(donation.money)}</p>
        <p className="card-text">Recipient: {TROOP_NAME} - {TROOP_LOCATION}</p>
      </div>
    </div>
  );
}

function TreeTagDetails({ donation }: { donation: any }) {
  const { data, isFetching, isError } = useSelector((state: any) => state.siteConfig);
  const pickupConfig = data.find((entry: any) => entry.name === 'pickup');
  const pickupDate = new Date(pickupConfig?.data?.date);
  const dropoffAllowed = pickupConfig?.data?.dropoffAllowed || false;
  const dropoffLocation = pickupConfig?.data?.dropoffLocation || '';
  const dropoffHours = pickupConfig?.data?.dropoffHours || '';
  if (!donation) {
    return null;
  }

  const num_tags = donation.num_tags || 0;
  const treeEmojis = Array(num_tags).fill('🌳');
  const mapColor = '#059c31';
  const mapColorOutline = donation.picked_up_status === 0 ? '#000000' : '#ffffff';

  return (
    <div className="card mt-4">
      <div className="card-body">
        <h5 className="card-title">Your Tree Tags</h5>
        <p>Pickup Date: {pickupDate.toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
        <p>Pickup Address: {prettyPrintAddress(donation.address)}</p>
        <div className="w-100" style={{ height: "300px" }}>
          <HouseMap
            houses={[{ lat: parseFloat(donation.lat), lng: parseFloat(donation.lng) }]}
            color={mapColor}
            outline={mapColorOutline}
          />
        </div>
        <p>{getStatus(pickupDate, donation)}</p>
        {donation.picked_up_status > 1 && dropoffAllowed && (
          <p>
            You can also drop your tree off at {dropoffLocation} between {dropoffHours}.
          </p>
        )}
        {treeEmojis.map((emoji, index) => (
          <div className="tree-tags" key={index}>
            <span>{emoji} Tree {index + 1}</span>
          </div>
        ))}
        <p>Something wrong? <Link to={`/contact?orderId=${donation.id}`}>Contact us</Link></p>
      </div>
    </div>
  );
}

export function prettyPrintAddress(address: string) {
  if (!address) return '';
  const replaceMap = {
    'Northeast': 'NE',
    'Northwest': 'NW',
    'Southeast': 'SE',
    'Southwest': 'SW',
    'North': 'N',
    'South': 'S',
    'East': 'E',
    'West': 'W',
    'Street': 'St',
    'Avenue': 'Ave',
    'Boulevard': 'Blvd',
    'Drive': 'Dr',
    'Court': 'Ct',
    'Place': 'Pl',
    'Circle': 'Cir',
    'Terrace': 'Ter',
    'Road': 'Rd',
    ', United States': '',
    ', Washington': ', WA',
  };
  for (const [key, value] of Object.entries(replaceMap)) {
    address = address.replace(new RegExp(key, 'g'), value);
  }
  return address;
}

function getStatus(pickupDate: Date, donation: any) {
  switch (donation.picked_up_status) {
    case 0:
      if (pickupDate > new Date()) {
        return <div className='alert alert-info'>
          <b>Status:</b> 🕒 Scheduled: We'll pick up your tree on {pickupDate.toLocaleDateString('en-US', { timeZone: 'UTC' })}.
        </div>;
      }
      return (
        <div className='alert alert-info'>
          <b>Status:</b> 🕒 Pending: We haven't picked up your tree yet.
        </div>
      );
    case 1:
      return <div className='alert alert-success'>
        <b>Status:</b> ✅ Complete: We've picked up your tree.
      </div>;
    case 2:
      return <div className='alert alert-danger'>
        <b>Status:</b> ❌ Not Found: We stopped by but couldn't find your tree. If this is a mistake, please <Link to="/contact">contact us</Link>.
      </div>;
    case 3:
      return <div className='alert alert-danger'>
        <b>Status:</b> ⚠️ Unpaid: We stopped by but couldn't find any donation attached to your tree. If you'd still like it picked up, please <Link to="/contact">contact us</Link>.
      </div>;
    default:
      return <div className='alert alert-danger'>
        <b>Status:</b> Unknown: Something went wrong while checking the status of your tree pickup.
      </div>;
  }
}

const ConfirmationPage: React.FC = () => {
  const { stripeCheckoutId } = useParams<{ stripeCheckoutId: string }>();
  const [order, setOrder] = React.useState<any>();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const loadOrder = (sessionId: string) => {
    setLoading(true);
    getOrder(sessionId)
      .then((order) => {
        setOrder(order);
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError("An error occurred while loading your donation.");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (stripeCheckoutId) {
      loadOrder(stripeCheckoutId);
    }
  }, [stripeCheckoutId]);

  if (loading) {
    return (
      <div className="container mt-5">
        <h1 className="mb-4">Thank You</h1>
        <LoadingIndicator />
        <p>Loading information about your donation...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <h1 className="display-4">Oops! We've run into an issue.</h1>
        <p className="alert alert-danger mt-4">{error}</p>
        <p>If this issue persists, reach out to us <Link to="/contact">here</Link>.</p>
        <button onClick={() => {
          if (stripeCheckoutId) loadOrder(stripeCheckoutId);
        }} className="btn btn-primary mt-3">Try Again</button>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Thank You</h1>
      <p>
        Your donation has been processed. Thank you for your support!
      </p>
      <DonationDetails donation={order} />
      {order && order.num_tags > 0 && <TreeTagDetails donation={order} />}
    </div>
  );
};

export default ConfirmationPage;