import React from 'react';
import { TROOP_LOCATION, TROOP_NAME } from '../config/Configuration';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HomePage: React.FC = () => {
  const { data, isFetching, isError } = useSelector((state: any) => state.siteConfig);
  const footerConfig = data.find((entry: any) => entry.name === 'home');
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col text-center">
          <h1 className="display-5 fw-bold text-body-emphasis">{TROOP_NAME}</h1>
          <p className="lead mb-4">{TROOP_LOCATION}</p>
          <p className="lead mb-4">
            {TROOP_NAME}'s annual fundraiser activity is to provide a service to the community of picking up and recycling Christmas trees.
            Please pre-purchase below and then set your tree out on your curb on {footerConfig ? footerConfig.data.treeTagPickupDay.toLocaleDateString('en-US', { timeZone: 'UTC' }) : "pickup day"} and we'll come by and remove it for you.
          </p>
          <ul className="list-unstyled mt-4">
            <li className="mb-2">
              <Link to="/donate" className="btn btn-primary">Donate</Link>
            </li>
            <li>
              <Link to="/tree-tag" className="btn btn-success">Recycle Your Christmas Tree</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomePage;