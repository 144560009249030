import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import OrderPage from './components/OrderPage';
import ProductPage from './components/Product';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactPage from './components/ContactPage';
import NotFoundPage from './components/NotFoundPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import FAQPage from './components/FAQPage';
import ConfirmationPage from './components/ConfirmationPage';
import { useEffect } from 'react';
import { fetchSiteConfig } from './store/slices';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteConfig() as any);
  }, [dispatch]);

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/donate" element={<ProductPage
            name="Donation"
            configName="donation"
            imageUrl="/assets/img/help.webp"
          />} />
          <Route path="/tree-tag" element={<ProductPage
            name="Recycle Your Christmas Tree"
            configName="tree_tag"
            imageUrl="/assets/img/tree.jpg"
            pickup
          />} />
          <Route path="/order/:id" element={<OrderPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/donation/:stripeCheckoutId" element={<ConfirmationPage />} />
          <Route path="/tree-tag/:stripeCheckoutId" element={<ConfirmationPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
