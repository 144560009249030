import React from 'react';
import { useSelector } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';

const FAQPage: React.FC = () => {
  const { data, isFetching, isError } = useSelector((state: any) => state.siteConfig);
  const faqConfig = data.find((entry: any) => entry.name === 'faq');
  if (isFetching) {
    return (
      <div className="container mt-5">
        <h1 className="mb-4">Frequently Asked Questions</h1>
        <LoadingIndicator />
      </div>
    );
  }
  if (isError || !faqConfig) {
    return (
      <div className="container mt-5">
        <h1 className="mb-4">Frequently Asked Questions</h1>
        <p className='alert alert-danger'>There was an error loading the FAQ. Please try again later.</p>
      </div>
    );
  }
  return (
    <div className="container mt-5">
      <h1 className="mb-4">Frequently Asked Questions</h1>
      <div className="accordion" id="faqAccordion">
        {faqConfig?.data?.questions.map((question: any, index: number) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0} aria-controls={`collapse${index}`}>
                {question.question}
              </button>
            </h2>
            <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#faqAccordion">
              <div className="accordion-body">
                {question.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;