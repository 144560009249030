import { API_URL } from "./Configuration";

export function callAPI(data: any): Promise<any> {
  return fetch(API_URL + "/tree-tags-form", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

export function getOrder(checkout_id: string): Promise<any> {
  return fetch(API_URL + "/tree-tags-get-order?session=" + checkout_id).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    }
  );
}
