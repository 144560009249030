import Map, {
  Source,
  Layer,
  MapProvider,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_TOKEN } from "../config/Configuration";

interface PickupMapProps {
  houses: { lat: number, lng: number }[];
  color?: string;
  outline?: string;
}

let map: any = null;

function HouseMap({ houses, color, outline }: PickupMapProps) {
  const geojson = {
    type: "FeatureCollection",
    features: houses.map((house) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [house.lng, house.lat],
      },
    })),
  };

  return (
    <div className="w-100 h-100">
      <MapProvider>
        <Map
          id="map"
          ref={(ref) => {
            if (ref) {
              map = ref.getMap();
              map.on('idle', function () {
                map.resize();
              });
            }
          }}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={"mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr"}
          initialViewState={{
            longitude: houses[0]?.lng || -122.21,
            latitude: houses[0]?.lat || 47.63,
            zoom: 16,
          }}
        >
          <Source id="houses" type="geojson" data={geojson}>
            <Layer
              id="house-points"
              type="circle"
              paint={{
                "circle-radius": {
                  'base': 2.75,
                  'stops': [
                    [12, 5],
                    [22, 180]
                  ]
                },
                "circle-stroke-width": 4,
                "circle-color": color || "#007cbf",
                "circle-stroke-color": outline || "#000",
              }}
            />
          </Source>
        </Map>
      </MapProvider>
    </div>
  );
}

export default HouseMap;
